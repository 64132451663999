import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At the beginning of June, I `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1136324324552396800"
      }}>{`launched`}</a>{` `}<a parentName="p" {...{
        "href": "https://sfsymbols.com"
      }}>{`sfsymbols.com`}</a>{` since there wasn't a reference available on the web for searching and filtering the list of 1500+ system icons included in iOS 13+.`}</p>
    <p>{`Yesterday I received a notice from an attorney representing Apple, informing me that this was not actually allowed under the terms of the Xcode license agreement, specifically section 2.12:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The system-provided images owned by Apple and documented as such in Apple's Human Interface Guidelines for iOS, watchOS, tvOS, and/or macOS (“System-Provided Images”) are licensed to You solely for the purpose of developing Applications for Apple-branded products that run on the system for which the image was provided.`}</p>
    </blockquote>
    <p>{`I definitely didn't realize this project would violate those terms, so I've happily removed the icon assets from sfsymbols.com and the associated GitHub page. As far as I understand, the site should be able to stay up, though no symbol images means it's not very useful, so I'll probably end up decomissioning it next year when the domain expires.`}</p>
    <p>{`Everything's always governed by a license, and I don't want to discount the great work of the folks who designed these icons. I do hope that in the future Apple will provide an interface for browsing the symbols that's not a Mac app, so that folks on non-Mac platforms (especially those who might work with iOS developers but aren't developers themselves) will be able to browse as well.`}</p>
    <p>{`Thanks to the ~2,300 unique users who visited the site since it launched, and those who tweeted about it! It was a fun little project and I learned a fair bit about React building it. I hope it was helpful 👍`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      